<template>
    <div>
        <query-form
            ref="queryForm"
            :fields="fields"
            :hasBtnBar="false"
            :actDisabled="actDisabled"
            @submit="handleQuery"
            @reset="handleReset"
        ></query-form>
    </div>
</template>

<script>
import QueryForm from "@/components/query-form";

import {
    getEntRecordFromProvinceQualification,
    h5PartnerApplyRegion,
} from "@/api/aptitude";

export default {
    props: {
        actDisabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        QueryForm,
    },
    data() {
        return {
            qualificationType: [],
            categoryOptions: [],
            provinceOptions: [],
            cityOptions: [],
            regionList: [],
            fromProvince: "广西壮族自治区",
            outProvince: undefined,
            fields: [
                {
                    label: "备案省份：",
                    field: "fromProvince",
                    component: "el-cascader",
                    props: {
                        lazy: false,
                        value: "value",
                        label: "label",
                        children: "child",
                        expandTrigger: "hover",
                        emitPath: true,
                        multiple: false,
                    },
                    options: [
                        {
                            label: "广西",
                            value: "广西壮族自治区",
                            child: [
                                {
                                    label: "省内企业",
                                    value: "广西壮族自治区",
                                },
                                {
                                    label: "入桂企业",
                                    value: "notProvince",
                                },
                            ],
                        },
                        {
                            label: "福建",
                            value: "福建省",
                            child: [
                                {
                                    label: "省内企业",
                                    value: "福建省",
                                },
                                {
                                    label: "入闽企业",
                                    value: "notProvince",
                                },
                            ],
                        },
                    ],
                    selection: [],
                    changeHandler: this.onFromProvinceChange,
                },
                {
                    label: "企业分类：",
                    field: "category",
                    component: "select",
                    flat: true,
                    options: [],
                    selection: [],
                },
                {
                    label: "企业名称：",
                    field: "enterpriseName",
                    placeholder: "请输入企业名称",
                    component: "input",
                    extra: [
                        {
                            label: "统一社会信用代码：",
                            placeholder: "请输入统一社会信用代码",
                            field: "creditCode",
                            component: "input",
                        },
                    ],
                },
                {
                    label: "企业注册属地：",
                    field: "province",
                    component: "select",
                    flat: false,
                    placeholder: "请选择省级",
                    options: this.provinceOptions,
                    changeHandler: this.loadCities,
                    extra: [
                        {
                            label: "请选择市级",
                            placeholder: "请选择市级",
                            field: "city",
                            component: "select",
                            options: this.cityOptions,
                        },
                    ],
                    withQueryBtn: true,
                    withResetBtn: true,
                },
            ],
        };
    },
    computed: {},
    methods: {
        extractProvinceOptions() {
            let options = [];
            (this.regionList || []).forEach((item) => {
                let opt = {
                    label: item.name,
                    value: item.adCode,
                };
                options.push(opt);
            });
            return options;
        },
        extractCityOptions(province) {
            let options = [];
            let target = (this.regionList || []).find(
                (item) => item.adCode == province
            );
            if (target) {
                (target.child || []).forEach((item) => {
                    let opt = {
                        label: item.name,
                        value: item.adCode,
                    };
                    options.push(opt);
                });
            }
            return options;
        },
        onFromProvinceChange(value) {
            let fromProvince = value;
            if (fromProvince) {
                console.log('onFromProvinceChange-->fromProvince=', fromProvince);
                if (Array.isArray(fromProvince) && fromProvince.length > 1) {
                    this.fromProvince = fromProvince[0];
                    if (fromProvince[1] == "notProvince") {
                        this.outProvince = 1;
                    } else {
                        this.outProvince = undefined;
                    }
                    this.loadCategoryOptions();
                }
            }
        },
        async loadH5PartnerApplyRegion() {
            const response = await h5PartnerApplyRegion();

            let records = response.data ? response.data : [];
            this.regionList = records;
            this.provinceOptions = this.extractProvinceOptions();
            this.setQueryFormOptions("province", this.provinceOptions);
        },
        async loadCategoryOptions() {
            let outProvince = this.outProvince;
            let params = {
                fromProvince: this.fromProvince
            };
            if (outProvince == 1) {
                params.outProvince = outProvince;
            }
            const response = await getEntRecordFromProvinceQualification(params);

            let records = response.data ? response.data : [];
            let categories = [];
            let fieldsOptions = [
                {
                    label: "全部",
                    value: "all",
                },
            ];
            (records || []).forEach((item) => {
                fieldsOptions.push({
                    label: item,
                    value: item,
                });
                categories.push({
                    label: item,
                    value: item,
                });
            });
            this.categoryOptions = categories;
            let target = (this.fields || []).find(
                (item) => item.field == "category"
            );
            if (target) {
                target.options = fieldsOptions;
                target.selection = ["全部"];
                this.setQueryFormSelection(target.field, target.selection);
            }
        },
        setQueryFormOptions(field, options) {
            let value = options || [];
            if (this.$refs.queryForm)
                this.$refs.queryForm.setOptions(field, value);
        },
        setQueryFormSelection(field, selection) {
            let value = selection && selection.length ? selection[0] : "全部";
            if (this.$refs.queryForm) {
                console.log(
                    "setQueryFormSelection-->field=" +
                        field +
                        ", selection=" +
                        selection
                );
                this.$refs.queryForm.setSelection(field, value);
            }
        },
        setQueryFormExtraOptions(field, extraField, options) {
            let value = options || [];
            if (this.$refs.queryForm)
                this.$refs.queryForm.setExtraOptions(field, extraField, value);
        },
        loadCities(province) {
            console.log("loadCities-->province=" + province);
            this.cityOptions = this.extractCityOptions(province);
            this.setQueryFormExtraOptions(
                "province",
                "city",
                this.cityOptions,
                0
            );
        },
        getProvinceName(province) {
            let target = (this.regionList || []).find(
                (item) => item.adCode == province
            );
            return target ? target.name : '';
        },
        getCityName(province, city) {
            let targetCity = null,
                target = (this.regionList || []).find(
                (item) => item.adCode == province
            );
            if (target) {
                targetCity = (target.child || []).find((item) => item.adCode == city);
            }
            return targetCity ? targetCity.name : '';
        },
        getAllCategoryNames() {
            let categoryNames = [];
            (this.categoryOptions || []).forEach(item => {
                if (item.value != 'all')
                    categoryNames.push(item.label);
            });
            return categoryNames;
        },
        handleReset() {
            this.qualificationType = [];
            this.$emit("reset");
        },
        handleQuery(orignFormData) {
            let { fromProvince, category, city, province, enterpriseName, creditCode } =
                orignFormData;
            let formData = {};

            formData.qualificationType = this.qualificationType || [];

            if (fromProvince) {
                console.log('fromProvince=', fromProvince);
                if (Array.isArray(fromProvince) && fromProvince.length > 1) {
                    formData.fromProvince = fromProvince[0];
                    if (fromProvince[1] == "notProvince") {
                        formData.notProvince = fromProvince[0];
                        formData.province = "";
                    } else {
                        formData.notProvince = "";
                        formData.province = fromProvince[1];
                    }
                }
            }

            if (category) {
                if (category == "全部") {
                    let categoryNames = this.getAllCategoryNames();
                    formData.qualificationType = [
                        ...categoryNames
                    ];
                } else {
                    formData.qualificationType = [category];
                }
            }
            // 去除省外两个字
            if (this.outProvince == 1) {
                formData.qualificationType = (formData.qualificationType || []).map(item=>{return item.replace('省外','')});
            }
            if (city) {
                formData.city = this.getCityName(province, city);
            }
            if (province) {
                formData.province = this.getProvinceName(province);
            }
            if (enterpriseName)
                formData.enterpriseName = enterpriseName;
            if (creditCode)
                formData.creditCode = creditCode;
            
            this.$emit("submit", formData);
        },
    },
    mounted() {
        this.loadCategoryOptions();
        this.loadH5PartnerApplyRegion();
        // this.$watch("cate1List", (newValue, oldValue) => {
        //     console.log(
        //         `cate1List changed from ${oldValue} to ${newValue}`
        //     );
        //     let target = (this.fields || []).find(
        //         (item) => item.field == "category"
        //     );
        //     if (target) {
        //         target.selection = newValue;
        //         this.setQueryFormSelection(target.field, newValue);
        //     }
        // });

        // this.$watch("qualificationList", (newValue, oldValue) => {
        //     console.log(
        //         `qualificationList changed from ${oldValue} to ${newValue}`
        //     );
        //     let target = (this.fields || []).find(
        //         (item) => item.field == "enterpriseName"
        //     );
        //     if (target) {
        //         target.selection = newValue;
        //     }
        // });
    },
};
</script>
