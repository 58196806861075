<template>
  <div class="mb-50">
    <div class="list-z">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
        <el-breadcrumb-item :to="{ path: '/enterprise/home' }" style="color: #FFFFFF;">首页</el-breadcrumb-item>
        <el-breadcrumb-item>备案查询</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="border-b">
      <query
        :actDisabled="isLoadingStatus()"
        @submit="handleQuery"
        @reset="handleReset"
      ></query>
    </div>
    <generic-table
      ref="genericTable"
      :get-data="getPageData"
      :columns="displayColumns"
      :page-size="size"
      :hide-pagination="false"
      :headerCellBkcolor="'#F8F8F8'"
      @linkAction="onLinkAction"
    ></generic-table>
  </div>
</template>
  
  <script>
import GenericTable from "@/components/generic-table";
import { getEntRecordPage } from "@/api/aptitude";
import Query from "./query.vue";
export default {
  components: {
    GenericTable,
    Query,
  },
  data() {
    return {
      size: 20,
      current: 1,
      isSearchingName: false,
      formData: {},
      displayColumns: [
        {
          label: "企业名称",
          prop: "enterpriseName",
          minWidth: 25,
          hasLink: true,
        },
        {
          label: "统一社会信用代码",
          prop: "creditCode",
          minWidth: 25,
        },
        { label: "企业法定代表人", prop: "legalPerson", minWidth: 20 },
        { label: "企业注册属地", prop: "provinceCity", minWidth: 20 },
      ],
    };
  },
  computed: {
    cate1List() {
      return this.formData && this.formData.cate1List
        ? this.formData.cate1List
        : [];
    },
    qualificationList() {
      return this.formData && this.formData.qualificationList
        ? this.formData.qualificationList
        : [];
    },
  },
  watch: {},
  mounted() {
    document.title = "备案查询_邦建通";
  },
  methods: {
    isLoadingStatus() {
      return this.$refs.genericTable && this.$refs.genericTable.isLoadingStatus;
    },
    handleQuery(formData) {
      console.log("handleQuery-->formData", formData);
      this.formData = formData;
      this.reloadTablePage(1);
    },
    handleReset() {
      console.log("handleReset...");
      if (this.formData && this.formData.qualificationType)
        this.formData.qualificationType = [];
    },
    reloadTablePage(current) {
      this.current = current;
      if (this.$refs.genericTable) this.$refs.genericTable.reload(this.current);
    },
    onLinkAction(prop, row) {
      if (prop == "enterpriseName") {
        console.log("linkAction", prop, row);
        this.$router.push({
          path: "/record/detail/",
          query: { fromProvince: row.fromProvince, creditCode: row.creditCode },
        });
      }
    },
    async getPageData({ size, current }) {
      this.size = size;
      this.current = current;

      let params = {
        size,
        current,
      };
      Object.assign(params, this.formData);

      console.log("getPageData-->params=", params);
      let records = [];
      let total = 0;
      try {
        // 获取数据的 API 请求
        const response = await getEntRecordPage(params);

        records = response.data ? response.data.records : [];
        total = response.data ? response.data.total : 0;
        (records || []).forEach((item) => {
          item.provinceCity =
            item.province + (item.city ? "-" + item.city : "");
        });
      } catch (res) {
      } finally {
        this.isSearchingName = false;
      }
      return {
        data: records,
        total: total,
      };
    },
  },
};
</script>
  <style scoped>
.table-header-area {
  display: flex;
}
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 35px;
  margin-bottom: 25px;
}
.fs-18 {
  font-size: 18px;
}
.mb-50 {
  margin-bottom: 50px;
}
.table-header {
  width: 520px;
  height: 60px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-left: 20px;
}
.border-b {
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #dcdcdc;
  text-align: left;
  padding-top: 48px;
  padding-left: 20px;
}

.table-title {
  width: 233px;
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  line-height: 60px;
}

.right-header {
  text-align: right;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 20px;
  flex: 1;
}
.view-more {
  font-size: 20px;
  font-family: Source Han Sans CN-Regular;
  font-weight: 500;
  color: #888888;
  line-height: 60px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.view-more::after {
  content: "";
  background-image: url(~@/assets/images/more11941.png);
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
}
::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  font-size: 18px;
}
::v-deep .el-link {
  font-size: 18px;
}
</style>
  